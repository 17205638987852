<template>

  <div>
    <transition class="fade-expand">
      <radial-options
        v-show="!isMobileView"
        class="transition-all pop duration-500"
        :stepProgress="insuranceMenuStepProgress"
        :elipseHRadius="elipseHRadius"
        @choiced="setChoicedItems"
        @next="next()"
        ref="insurance-web"
      >
      </radial-options>
    </transition>
    <transition name="fade-expand">
      <slide-options
        v-show="isMobileView"
        class="transition-all pop duration-500"
        @start="insuranceMenuStepProgress = 2"
        :stepProgress="insuranceMenuStepProgress"
        :elipseHRadius="elipseHRadius"
        @choiced="setChoicedItems"
        @next="next()"
        ref="insurance-mobile"
      >
      </slide-options>
    </transition>
    <div
      v-if="currentStep == 2">
      <div
        v-for="item in selectedProductCategories"
        :key="item.id"
      >
        <img :src="item.image" width="40" height="40" class="selected" />
      </div>
    </div>
  </div>

</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import RadialOptions from "../components/InsuranceOptions/RadialOptionsSimple/RadialOptions.vue";
import SlideOptions from "../components/InsuranceOptions/SlideOptionsSimple/SlideOptions.vue";

export default {
  name: "OurServicesPage",
  data: () => ({
    elipseHRadius: 300,
    elipseHRadiusBase: 0,
    insuranceMenuStepProgress: 1,
    currentStep: 1,
    window: {
      width: 0,
      height: 0
    },
    info: ""
  }),
  components: {
    RadialOptions,
    SlideOptions
  },
  computed: {
    ...mapGetters(["shoppingCar"]),
    selectedProductCategories() {
      return this.$store.state.BuyProcessStore.productCategories.getNUserSelection();
    },
    isMobileView() {
      return this.$store.state.GeneralConfigStore.isMobileView;
    }
  },
  mounted() {
    this.setInitialState();
  },
  destroyed() {},
  methods: {
    ...mapMutations(["setUserSelection"]),
    resetVariables() {
      this.setUserSelection([]);
      this.elipseHRadius = 300;
      this.elipseHRadiusBase = 0;
      this.insuranceMenuStepProgress = 1;
      this.currentStep = 1;
      this.window = { width: 0, height: 0 };
      this.isMobileView = true;
    },
    async setInitialState() {
      // Eliminamos la imagen de background principal, esto habilita el video
      this.$store.commit("updateBgApp", "");
      this.calculateScaleRadialMenu();
      if (this.isMobileView) {
        this.$refs["insurance-mobile"].start(); //Comienza la animación de entrada
      } else {
        this.$refs["insurance-web"].start(); //Comienza la animación de entrada
      }
    },
    calculateScaleRadialMenu() {
      let topbar = document.getElementsByClassName("topbar-wraper")[0];
      let disponibleRadius = 0;
      if (document.documentElement.clientHeight * 0.8 <= document.documentElement.clientWidth) {
        disponibleRadius = (document.documentElement.clientHeight * 0.8 - topbar.offsetHeight * 2.5) / 2;
      } else {
        disponibleRadius = (document.documentElement.clientWidth - topbar.offsetHeight * 2.5) / 2;
      }
      this.elipseHRadius = disponibleRadius.toFixed(0);
      this.elipseHRadiusBase = this.elipseHRadius;
    },
    setChoicedItems(data) {
      this.$store.state.BuyProcessStore.productCategories.setUserSelection(
        data.choiced
      )
    },
    next() {
      /* Next causes that the current quote becomes obsolete, and reset of shoppingCar*/
      if (this.shoppingCar.getItems().length) {

        this.$swal({
          icon: "error",
          title: "Carrito vaciado",
          text:"Los items del carrito corresponden a una cotización distinta y fueron eliminados",
          showConfirmButton:false,
          timer: 10000,
        });

      }
      this.shoppingCar.reset()
      this.$router.push("data-for-services")
    }
  }
};
</script>
<style >
#eslogan .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em;
}

#eslogan .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: var(--primary);
  transform-origin: 0 50%;
}

#eslogan .line1 {
  top: 0;
  left: 0;
}

#eslogan .letter {
  display: inline-block;
  line-height: 1em;
  min-width: 10px;
}
</style>
